import { pathOr } from 'ramda';
import { ENVIRONMENT, FEATURE_FLAGS } from '../../config';

export const createFeatureFlagGetter = (
  environment = 'localhost',
  featureFlags,
) => {
  const thisFeatureFlags =
    featureFlags[environment] || featureFlags.default[environment] || {};
  const OVERWRITE = {
    POINTS_PAY: thisFeatureFlags.QFF_AUTH && thisFeatureFlags.POINTS_PAY,
  };

  return (key) => pathOr(thisFeatureFlags[key], [key], OVERWRITE);
};

export default createFeatureFlagGetter(ENVIRONMENT, FEATURE_FLAGS);
