import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Box, Flex, Icon, Paragraph, Text } from '@qga/roo-ui/components';
import { parseOccupantCount } from '@experiences-ui/shared/utils';
import ClickOutside from '@/components/ClickOutside';
import useForm from '@/hooks/useForm';
import { isGT } from '@/lib/validator';
import Occupants from '@/propTypes/Occupants';
import Button from '@/components/Button';
import TravellerInput from './components/TravellerInput';
import Link from '@/components/Link';
import ReactMarkdown from 'react-markdown';
import withProps from '@/components/withProps';
import styled from '@emotion/styled';
import {
  getWarningMessage,
  isMaxChildrenWarningActive,
  isMaxInfantsWarningActive,
  isMaxTravellerWarningActive,
  isTotalChildrenValid,
  isTotalInfantsValid,
  isTotalTravellersInvalid,
  MAX_INFANTS_MESSAGE,
  MAX_KFF_CHILDREN_MESSAGE,
  MAX_TRAVELLERS_MESSAGE,
  MIN_ADULTS_MESSAGE,
} from '@/components/Travellers/components/TravellersFormDropdown/validations';

const SmallParagraph = withProps({
  mb: 0,
  lineHeight: 1.5,
  color: 'text.body',
})(Paragraph);

const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TravellersFormDropdown = ({
  occupants,
  onCancel,
  onSubmit,
  fullWidth,
}) => {
  const { getValue, getError, handleValueChange, handleSubmit, validate } =
    useForm(occupants, (data) => {
      if (isTotalTravellersInvalid(data)) {
        return;
      }
      onSubmit(data);
    });

  const getNumberValue = (key) => parseOccupantCount(getValue(key));

  const adults = getNumberValue('adults');
  const children = getNumberValue('children');
  const infants = getNumberValue('infants');
  const passengers = { adults, children, infants };

  const isMaxTravellers = isMaxTravellerWarningActive(passengers);
  const isMaxInfants = isMaxInfantsWarningActive(passengers);
  const isMaxChildren = isMaxChildrenWarningActive(passengers);

  // Non-blocking warning: Appear BEFORE going into invalid state
  const warning = getWarningMessage(passengers);
  // Blocking error: Appears when IN invalid state (should not be possible but here for safety)
  const error =
    (isTotalTravellersInvalid(passengers) && MAX_TRAVELLERS_MESSAGE) ||
    getError('adults') ||
    getError('children') ||
    getError('infants');
  const message = error || warning;

  const onClickOutside = (event) => {
    if (error) {
      return onCancel();
    }
    handleSubmit(event);
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClickOutside onOutsideClick={onClickOutside}>
      <form onSubmit={handleSubmit} data-testid="TRAVELLERS_FORM">
        <Box
          width={fullWidth ? '100%' : ['343px', null, '423px']}
          border="1"
          borderColor="border"
          boxShadow="soft"
          mt="1"
          bg="white"
          borderRadius="sm"
        >
          <InputsContainer
            p="8"
            pb="6"
            pt="7"
            borderBottom="1"
            borderColor="border"
          >
            <TravellerInput
              id="adults"
              dataTestId="ADULTS"
              label="Adults"
              value={adults}
              onChange={handleValueChange('adults', [
                isGT(0),
                MIN_ADULTS_MESSAGE,
              ])}
              minValue={1}
              disabledDecrement={isMaxInfants || isMaxChildren}
              disabledIncrement={isMaxTravellers}
            />
            <TravellerInput
              id="children"
              dataTestId="CHILDREN"
              label="Children"
              hint="2-11 years"
              value={children}
              onChange={handleValueChange('children', [
                (value) =>
                  isTotalChildrenValid({
                    adults: getNumberValue('adults'),
                    children: value,
                  }),
                MAX_KFF_CHILDREN_MESSAGE,
              ])}
              disabledIncrement={isMaxTravellers || isMaxChildren}
            />
            <TravellerInput
              id="infants"
              dataTestId="INFANTS"
              label="Infants"
              hint="< 2 years"
              value={infants}
              onChange={handleValueChange('infants', [
                (value) =>
                  isTotalInfantsValid({
                    adults: getNumberValue('adults'),
                    infants: value,
                  }),
                MAX_INFANTS_MESSAGE,
              ])}
              disabledIncrement={isMaxTravellers || isMaxInfants}
            />
            {message && (
              <Flex
                data-testid="ERROR"
                mt={1}
                fontSize="sm"
                alignItems="flex-start"
              >
                <Icon color="text.hint" mr={2} name="error" />
                <ReactMarkdown
                  source={message}
                  renderers={{
                    paragraph: SmallParagraph,
                    link: Link.Inline,
                  }}
                />
              </Flex>
            )}
          </InputsContainer>
          <Flex py="4" px="8" justifyContent="flex-end">
            <Button
              dialogue
              variant="secondary"
              data-testid="SUBMIT"
              type="submit"
              width="120px"
              p="8px !important"
            >
              <Text lineHeight="19px" fontSize="xs">
                Confirm
              </Text>
            </Button>
          </Flex>
        </Box>
      </form>
    </ClickOutside>
  );
};

TravellersFormDropdown.propTypes = {
  occupants: PropTypes.shape(Occupants).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

export default TravellersFormDropdown;
