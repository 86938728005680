import { useEffect } from 'react';
import dataLayer from '@/lib/dataLayer';

const useDataLayer = (event) => {
  useEffect(() => {
    if (!event) {
      return;
    }

    dataLayer.push(event);
  }, [event]);

  return dataLayer;
};

export default useDataLayer;
