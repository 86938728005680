import isEmpty from 'validator/lib/isEmpty';
import isDecimal from 'validator/lib/isDecimal';
import isPostalCode from 'validator/lib/isPostalCode';
import isInt from 'validator/lib/isInt';
import { range } from 'ramda';

export { default as isEmail } from 'validator/lib/isEmail';
export { default as isLength } from 'validator/lib/isLength';
export { default as isMobilePhone } from 'validator/lib/isMobilePhone';

export const isPostcode = (...args) => isPostalCode(...args, 'AU');
export const isNotEmpty = (...args) => !isEmpty(...args);
export const isQFF = (value) => /(^(([0-9]){10}|([0-9]){1,7})$)/.test(value);
export const isQFFOrEmpty = (value) => isQFF(value) || !value;
export const isGT =
  (minValue, equal = false) =>
  (value) =>
    equal ? value >= minValue : value > minValue;
export const isLT =
  (maxValue, equal = false) =>
  (value) =>
    equal ? value <= maxValue : value < maxValue;

export const isPotentialNumeric = (value, digits) => {
  if (isEmpty(value)) {
    return true;
  }

  if (digits === 0) {
    return isInt(value);
  }

  return range(0, digits + 1).some((d) =>
    isDecimal(value, { decimal_digits: d }),
  );
};

export const isName = (value) => /^[ \u00c0-\u01ffa-zA-Z'-]+$/.test(value);
