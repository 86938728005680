import { Text as BaseText } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { textStyle, color } from 'styled-system';

const ErrorMessage = styled(BaseText)(textStyle, color);
ErrorMessage.defaultProps = {
  ...BaseText.defaultProps,
  textStyle: 'small',
  color: 'text.error',
};

export default ErrorMessage;
