import type { SanityApi } from '@/v2/services/sanity/types';

import { areIntervalsOverlapping, min, max } from 'date-fns';
import { toDate } from 'date-fns-tz';

import { getCampaignEndMessage } from '@/libs/v2/utils/getCampaignEndMessage';
import { parseProcuredOffer } from '../procuredOffer';

import { isProcuredOfferActive } from '@experiences-ui/shared/utils';

import enrichedCampaignWithTotalTravellersAndPrice from '../../../../../../utils/enrichedCampaignWithTotalTravellersAndPrice';
import formatDate from '@/libs/utils/formatDate';

const emptyCalendar = {
  title: '',
  unsupportedRouteMessage: '',
  noOriginMessage: '',
  prefix: null,
  routes: null,
};

const parseCampaignHero = ({
  campaignLandingPage,
  end,
  image,
}: Pick<
  SanityApi.Response.Campaign,
  'campaignLandingPage' | 'end' | 'image'
>): SanityApi.ParsedResponse.Hero => {
  const { headline, tagline } = campaignLandingPage!;

  return {
    cta: null,
    description: tagline,
    width: 1600,
    image: image,
    tag: end ? getCampaignEndMessage({ dateRange: end }) : null,
    termsConditions: null,
    title: headline,
  };
};

const parseCampaignHomePageHero = ({
  end,
  homePageHeroBanner,
  image,
  slug,
}: Pick<
  SanityApi.Response.Campaign,
  'end' | 'homePageHeroBanner' | 'image' | 'slug'
>): SanityApi.ParsedResponse.Hero => {
  const {
    CTA = '',
    headline,
    termsConditions,
    tagline,
  } = homePageHeroBanner || {};

  return {
    cta: {
      label: CTA,
      slug: slug === 'deals' ? `/${slug}` : `/deals/${slug}`,
    },
    description: tagline ?? null,
    width: 1600,
    image: image,
    tag: end ? getCampaignEndMessage({ dateRange: end }) : null,
    termsConditions: termsConditions ?? null,
    title: headline ?? null,
  };
};

const parseFlightsToCalendar = (
  calendar: SanityApi.ParsedResponse.Campaign['calendar'],
  flightsData: SanityApi.Response.Flight,
): SanityApi.ParsedResponse.Campaign['calendar'] => {
  const { routes } = flightsData;

  const returnedCalendar = calendar ?? emptyCalendar;

  if (routes) {
    const calendarRoutes = routes.map(
      ({ origin, destination, travelDates }) => {
        const dates =
          (travelDates &&
            travelDates.map(
              ({ start, end }) =>
                `${formatDate(start.local)} - ${formatDate(end.local)}`,
            )) ||
          null;

        return {
          message: `${
            returnedCalendar?.prefix ? `${returnedCalendar.prefix} ` : ''
          }${dates && `Travel between ${dates.join(', ').trim()}`}`,
          route: `${origin}${destination}`,
        };
      },
    );

    return {
      ...returnedCalendar,
      routes: calendarRoutes,
    };
  }

  return {
    ...returnedCalendar,
  };
};

export const parseFlightDatesAndProcuredOfferDates = (
  procuredOffer: SanityApi.ParsedResponse.ProcuredOffer,
  selectedRoute: SanityApi.Response.CalendarRoute,
  campaign: SanityApi.Response.Campaign,
): string | null => {
  const routes = campaign.flights.routes;
  const calendar = campaign.calendar;

  const route = routes?.find(
    (r) => `${r.origin}${r.destination}` === selectedRoute.route,
  );

  const {
    start: procuredOfferTravelDatesStart,
    end: procuredOfferTravelDatesEnd,
  } = procuredOffer.travelDateRange;

  const procuredOfferTravelDatesStartAsDate = toDate(
    procuredOfferTravelDatesStart.utc,
  );
  const procuredOfferTravelDatesEndAsDate = toDate(
    procuredOfferTravelDatesEnd.utc,
  );

  const dates = route?.travelDates?.flatMap(
    ({ start: flightRouteDatesStart, end: flightRouteDatesEnd }) => {
      const flightRouteDatesStartAsDate = toDate(flightRouteDatesStart.local, {
        timeZone: flightRouteDatesStart.timezone,
      });
      const flightRouteDatesEndAsDate = toDate(flightRouteDatesEnd.local, {
        timeZone: flightRouteDatesEnd.timezone,
      });

      if (
        !areIntervalsOverlapping(
          {
            start: procuredOfferTravelDatesStartAsDate,
            end: procuredOfferTravelDatesEndAsDate,
          },
          {
            start: flightRouteDatesStartAsDate,
            end: flightRouteDatesEndAsDate,
          },
        )
      ) {
        return [];
      }

      const intervalStart = max([
        procuredOfferTravelDatesStartAsDate,
        flightRouteDatesStartAsDate,
      ]);

      const intervalEnd = min([
        procuredOfferTravelDatesEndAsDate,
        flightRouteDatesEndAsDate,
      ]);

      return [`${formatDate(intervalStart)} - ${formatDate(intervalEnd)}`];
    },
  );

  return dates && dates.length > 0
    ? `${calendar?.prefix ? `${calendar.prefix} ` : ''}${`Travel between ${dates
        .join(', ')
        .trim()}`}`
    : null;
};

const parseCampaign = (
  campaignData: SanityApi.Response.Campaign,
): SanityApi.ParsedResponse.Campaign => {
  const {
    calendar,
    campaignLandingPage = null,
    end,
    flights,
    homePageHeroBanner,
    image,
    slug,
    tiles,
    ...rest
  } = campaignData;

  return enrichedCampaignWithTotalTravellersAndPrice({
    ...rest,
    calendar: flights ? parseFlightsToCalendar(calendar, flights) : calendar,
    campaignLandingPage,
    end,
    flights,
    hero:
      (campaignLandingPage &&
        parseCampaignHero({ campaignLandingPage, end, image })) ??
      null,
    homePageHero:
      homePageHeroBanner &&
      parseCampaignHomePageHero({
        end,
        homePageHeroBanner,
        image,
        slug,
      }),
    image,
    slug,
    tiles:
      (tiles &&
        tiles
          .filter((tile) => {
            return (
              (tile.type === 'PROCURED_OFFER' &&
                isProcuredOfferActive(tile.procuredOffer)) ||
              tile.type === 'STANDARD_PROPERTY'
            );
          })
          .map((tile) => {
            if (tile.type === 'PROCURED_OFFER') {
              return {
                ...tile,
                procuredOffer: parseProcuredOffer(tile.procuredOffer!),
                sashing: campaignData.sashing,
              };
            } else {
              return {
                ...tile,
                sashing: campaignData.sashing,
              };
            }
          })) ||
      null,
  });
};

export default parseCampaign;
