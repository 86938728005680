import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { SCREEN_SIZE } from '../../../../lib/theme/breakPoints';
import Text from '@/components/Text';

const StyledBox = styled(Flex)`
  border-radius: 0px;
  min-height: ${themeGet('space.12')};

  ${SCREEN_SIZE.MD} {
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const CampaignHeader = ({ title }) => (
  <StyledBox
    data-testid="CAMPAIGN-HEADER"
    px="5"
    py={[6, null, 3]}
    bg="ui.badgeBackground"
    alignItems="center"
    justifyContent="center"
  >
    <Text
      color="black"
      bold
      fontSize={['14px', null, '16px']}
      textStyle="heading"
    >
      {title}
    </Text>
  </StyledBox>
);

CampaignHeader.defaultProps = {
  title: '',
};

CampaignHeader.propTypes = {
  title: PropTypes.string,
};

export default CampaignHeader;
