import { Icon, Input, Box } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import LinkButton from '@/components/LinkButton';
import Link from '@/components/Link';
import ErrorMessage from '../ErrorMessage';
import styled from '@emotion/styled';

const overrideRenderers = () => ({
  paragraph: ErrorMessage,
  link: Link.Inline,
});

const StyledInput = styled(Input)`
  font-weight: bold;
  font-size: 32px;
  margin: 0;
  padding: 0;
  width: 54px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledLinkButton = styled(LinkButton)`
  &:disabled {
    opacity: 1;
  }
`;

const IncrementableNumber = ({
  value,
  onChange,
  minValue,
  maxValue,
  error,
  disabledIncrement,
  disabledDecrement,
  ...props
}) => {
  const isDecrementDisabled = disabledDecrement || value <= minValue;
  const isIncrementDisabled = disabledIncrement || value >= maxValue;

  return (
    <Box>
      <InputContainer data-testid="WRAPPER">
        <StyledLinkButton
          noPadding
          data-testid="MIN"
          onClick={() => onChange(value - 1)}
          disabled={isDecrementDisabled}
        >
          <Icon
            name="removeCircleOutlineRounded"
            size={40}
            color={isDecrementDisabled ? 'disabled' : 'secondary'}
          />
        </StyledLinkButton>
        <StyledInput
          data-testid="INPUT"
          textAlign="center"
          border={0}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          value={value}
          readOnly
        />
        <StyledLinkButton
          noPadding
          data-testid="ADD"
          onClick={() => onChange(value + 1)}
          disabled={isIncrementDisabled}
        >
          <Icon
            name="addCircleOutlineRounded"
            size={40}
            color={isIncrementDisabled ? 'disabled' : 'secondary'}
          />
        </StyledLinkButton>
      </InputContainer>
      {error && (
        <ReactMarkdown source={error} renderers={overrideRenderers()} />
      )}
    </Box>
  );
};

IncrementableNumber.defaultProps = {
  value: 0,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
};

IncrementableNumber.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabledDecrement: PropTypes.bool,
  disabledIncrement: PropTypes.bool,
};

export default IncrementableNumber;
