import { MAX_TRAVELLERS_COUNT } from '@/constants';
import getFeatureFlag from '@/utils/getFeatureFlag';

export const MAX_TRAVELLERS_MESSAGE =
  'A maximum of 9 travellers can be booked at a time.';
export const MIN_ADULTS_MESSAGE = 'You must select at least 1 adult';
export const MAX_INFANTS_MESSAGE =
  'One infant (< 2 years) per adult, can be booked at a time.';
export const MAX_KFF_CHILDREN_MESSAGE =
  'To book more children than adults on your holiday, please ' +
  '[contact our call centre](https://www.jetstar.com/au/en/holidays/contact-us)';

// Warning Validation - Warnings are non-blocking and appear before going into
// an invalid Error state
export const isMaxTravellerWarningActive = ({ adults, infants, children }) =>
  adults + children + infants >= MAX_TRAVELLERS_COUNT;

export const isMaxInfantsWarningActive = ({ adults, infants }) =>
  infants >= adults;

export const isMaxChildrenWarningActive = ({ adults, children }) => {
  if (!getFeatureFlag('KFF_CHILDREN_LIMIT')) {
    return null;
  }
  return children >= adults;
};

export const getWarningMessage = (passengers) => {
  return (
    (isMaxTravellerWarningActive(passengers) && MAX_TRAVELLERS_MESSAGE) ||
    (isMaxChildrenWarningActive(passengers) && MAX_KFF_CHILDREN_MESSAGE) ||
    (isMaxInfantsWarningActive(passengers) && MAX_INFANTS_MESSAGE)
  );
};

// Error Validation - Errors are blocking and appear when in an invalid state.
export const isTotalTravellersInvalid = ({ adults, infants, children }) =>
  adults + children + infants > MAX_TRAVELLERS_COUNT;

export const isTotalInfantsValid = ({ adults, infants }) => {
  return infants <= adults;
};

export const isTotalChildrenValid = ({ adults, children }) => {
  if (!getFeatureFlag('KFF_CHILDREN_LIMIT')) {
    return true;
  }
  return children <= adults;
};
