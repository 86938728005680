import { prependFlightCampaignInclusion } from '@/utils/campaigns';
import PropTypes from 'prop-types';
import { path, take } from 'ramda';

const getProcuredOfferInclusions = (procuredOffer, size, campaignPills) => {
  const flightBadgeText = procuredOffer?.sashing?.flightPillText;

  const hasInclusions =
    procuredOffer?.highlights?.inclusions &&
    procuredOffer?.highlights?.inclusions.length > 0;

  const initSize =
    size === undefined
      ? hasInclusions
        ? procuredOffer?.highlights?.inclusions.length + 1
        : 0
      : size;

  let terms = '';
  if (Object.keys(campaignPills).length) {
    const flightCampaign = path(
      [procuredOffer?.sashing?.flightPromoCode],
      campaignPills,
    );
    terms = flightCampaign?.termsConditions;
  }
  const inclusions =
    hasInclusions && !!flightBadgeText
      ? take(
          initSize,
          prependFlightCampaignInclusion(
            procuredOffer?.highlights?.inclusions,
            flightBadgeText,
            terms,
          ),
        )
      : procuredOffer?.highlights?.inclusions;
  return inclusions;
};

getProcuredOfferInclusions.defaultProps = {
  procuredOffer: undefined,
  size: undefined,
  campaignPills: undefined,
};

getProcuredOfferInclusions.propTypes = {
  procuredOffer: PropTypes.object.isRequired,
  size: PropTypes.number,
  campaignPills: PropTypes.object.isRequired,
};

export default getProcuredOfferInclusions;
