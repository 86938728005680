import { useMemo, useRef } from 'react';
import isDataNoResults from '@/utils/isDataNoResults';
import userInteractionEvent from '@/utils/userInteractionEvent';
import useDataLayer from './useDataLayer';

const toUnsuccessfulSearch = (availability = {}) => {
  const noResults = [];

  if (!availability.flights) {
    noResults.push('No flights');
  }

  if (!availability.hotels) {
    noResults.push('No hotels');
  }

  return `Unsuccessful Search (${noResults.join(' & ')})`;
};

const useResultsAvailableEvent = (searchQuery, data, extra) => {
  const searchQueryRef = useRef();
  searchQueryRef.current = searchQuery;

  const extraRef = useRef();
  extraRef.current = extra;

  const withResultsEvent = useMemo(() => {
    if (!searchQueryRef.current || !data) {
      return null;
    }

    const { pageType, destinationName, availability } = extraRef.current;

    const value = !isDataNoResults(data)
      ? 'Successful Search'
      : toUnsuccessfulSearch(availability);

    return userInteractionEvent(pageType, destinationName, value);
  }, [data]);

  useDataLayer(withResultsEvent);
};

export default useResultsAvailableEvent;
