import IncrementableNumber from '@/components/IncrementableNumber';
import { Flex } from '@qga/roo-ui/components';
import React from 'react';
import Text from '@/components/Text';
import PropTypes from 'prop-types';

const TravellerInput = ({
  id,
  label,
  hint,
  value,
  onChange,
  dataTestId,
  minValue = 0,
  maxValue,
  disabledIncrement,
  disabledDecrement,
}) => {
  return (
    <Flex
      data-testid={dataTestId}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flexDirection="column">
        <label htmlFor={id}>
          <Text bold>{label}</Text>
        </label>
        {hint && (
          <Text mt="1" fontSize="xs" lineHeight="20px">
            {hint}
          </Text>
        )}
      </Flex>
      <IncrementableNumber
        id={id}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        onChange={onChange}
        disabledDecrement={disabledDecrement}
        disabledIncrement={disabledIncrement}
      />
    </Flex>
  );
};

TravellerInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  dataTestId: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disabledIncrement: PropTypes.bool,
  disabledDecrement: PropTypes.bool,
};

export default TravellerInput;
