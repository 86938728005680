import { useRef, useState, useCallback } from 'react';

import { always, ifElse, isEmpty, pathEq, pathOr, propOr, omit } from 'ramda';
import trim from 'validator/lib/trim';

const getFormValue = (event) => {
  const eventPath = (v) => ['target', v];
  const isCheckBox = (e) => pathEq('checkbox', ['target', 'type'])(e);
  const value = ifElse(
    isCheckBox,
    always(eventPath('checked')),
    always(eventPath('value')),
  )(event);
  return pathOr(event, value, event);
};

const mapDataFromFormData = (formData) => {
  const data = {};

  Object.keys(formData).forEach((key) => {
    const { value } = formData[key];
    data[key] = typeof value === 'string' ? trim(value) : value;
  });

  return data;
};

const mapFormDataFromData = (data) => {
  const formData = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];

    formData[key] = {
      value,
    };
  });

  return formData;
};

const getErrorMessage = (value, validation) => {
  if (!validation) {
    return null;
  }

  if (validation.every(Array.isArray)) {
    let message;

    for (let i = 0; i < validation.length; i += 1) {
      message = getErrorMessage(value, validation[i]);

      if (message) {
        break;
      }
    }

    return message;
  }

  const [validator, message, ...options] = validation;
  const result = validator(value, ...options);

  if (result) {
    return null;
  }

  return message;
};

const useForm = (initialData = {}, onSubmit) => {
  // NOTE: We should separate form fields from form validation state, errors, etc.
  const formData = useRef(mapFormDataFromData(initialData));
  // Basically just triggering a rerender for now but it should
  // keep track of the form state like errors, isValid, etc.
  const [, setFormState] = useState(mapFormDataFromData(initialData));
  const [formDirty, setFormDirty] = useState(false);
  const registeredValidation = useRef({});

  const getFormData = (key) => formData.current[key] || {};

  const setFormData = useCallback(
    (data) => {
      formData.current = data;
      setFormState({ ...formData.current });
    },
    [setFormState],
  );

  const getValue = (key) => {
    const data = getFormData(key);
    return propOr('', 'value')(data);
  };

  const setFormValue = (key, formValues) => {
    formData.current[key] = {
      ...formData.current[key],
      ...formValues,
    };
  };

  const updateValue = (key, value, validation) => {
    const error = getErrorMessage(value, validation);

    setFormValue(key, {
      value,
      error,
      dirty: true,
    });
  };

  const handleValueChange = (key, validation) => {
    registeredValidation.current[key] = validation;

    return (event) => {
      updateValue(key, getFormValue(event), validation);

      Object.keys(registeredValidation.current)
        .filter((k) => key !== k)
        .forEach((k) => {
          updateValue(k, getValue(k), registeredValidation.current[k]);
        });

      setFormState({ ...formData.current });
    };
  };

  const getError = (key, behavior) => {
    const { error, dirty } = getFormData(key);

    if (
      behavior &&
      ((behavior.onFormDirty && !formDirty) ||
        (behavior.onInputDirty && !dirty))
    ) {
      return null;
    }

    return error;
  };

  const handleReset = () => setFormData(mapFormDataFromData(initialData));

  const handleResetFields = (fields = []) =>
    setFormData(omit(fields, formData.current));

  const deregisterValidation = (validation) => {
    delete registeredValidation.current[validation];
  };
  const deregisterValidations = useCallback(
    (validations) => validations.forEach(deregisterValidation),
    [],
  );

  const validate = () => {
    setFormDirty(true);

    const error = {};

    Object.keys(registeredValidation.current).forEach((key) => {
      const prevError = getError(key);

      if (prevError) {
        error[key] = prevError;

        return;
      }

      const validation = registeredValidation.current[key];
      const value = getValue(key);

      const errorMessage = getErrorMessage(value, validation);

      if (!errorMessage) {
        return;
      }

      error[key] = errorMessage;
    });

    if (isEmpty(error)) {
      return true;
    }

    Object.keys(error).forEach((key) => {
      setFormValue(key, { error: error[key] });
    });

    return false;
  };

  const createSubmitHandler = (cb) => (event) => {
    if (event) {
      event.preventDefault();
    }

    const result = validate();
    if (!result) {
      return;
    }

    if (!cb) {
      return;
    }

    const data = mapDataFromFormData(formData.current);

    cb(data);
  };

  return {
    getFormData,
    setFormData,
    getValue,
    getError,
    validate,
    handleValueChange,
    createSubmitHandler,
    handleSubmit: createSubmitHandler(onSubmit),
    handleReset,
    handleResetFields,
    deregisterValidations,
    setFormDirty,
  };
};

export default useForm;
